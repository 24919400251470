import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Row, Col, Select, Switch } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { getKeySessionStorage, getSesion, sendServer, toast } from '../../utils/utils'
const { Option } = Select

const HijosAdd = ({ visible, setVisible, loadData, dataUpd, setDataUpd }) => {

  const [form] = Form.useForm();
  const [episiotomia, setEpisiotomia] = useState(false);
  const [metodo, setMetodo] = useState('');

  useEffect(() => {
    const flagMetodo = dataUpd?.id?'actualizar':'agregar';
    setMetodo(flagMetodo);
    if (flagMetodo === 'actualizar') {
      form.setFieldsValue({
        ...dataUpd,
      })
      setEpisiotomia(dataUpd.episiotomia);
    }
    return () => { 
      setDataUpd({});
      form.setFieldsValue({});
      setEpisiotomia(false);
    }
  }, [visible])

  const handleSubmit = async (item) => {

    try {
      const valid = otrosDatos(item);
      if(valid !== 'ok') {
        throw Error(valid);
      }
      const { msg } = await sendServer(item, 'POST', `master/infoHijos/${metodo}`);
      loadData();
      toast('success', 'fitconluapp', 'Guardado correctamente');
      closeModal();
    } catch (e) {
      toast('warning', 'fitconluapp', `No se puede guardar, ${e.message}`);
    }

  };

  /**
   * valida el ro para poder agregar
   * @param {*} item 
   */
  const otrosDatos = (item) => {
    const { rol } = getSesion();
    const { idHistoria } = getKeySessionStorage('filtroHijos') || '';
    const { id } = getKeySessionStorage('historiaSelecionada') || '';
    if(rol === 'admin') {
      if(!idHistoria) {
        return 'debe haber una historia clínica'
      }
      item.idHistoriaClinica = idHistoria;
    } else {
      if(!id) {
        return 'debe haber una historia clínica'
      }
      item.idHistoriaClinica = id;
    }
    return 'ok';
  }

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  }

  return (
    <Modal
      title="Agregar hijo"
      visible={visible}
      width={1000}
      onOk={form.submit}
      onCancel={() => { closeModal() }}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        name="frmHijo_add"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">

          {/* ID */}
          <Form.Item
            name="id"
            rules={[
              { required: false },
            ]}
            hidden={true}
          >
            <Input />
          </Form.Item>

          {/* ID historia clinica */}
          <Form.Item
            name="idHistoriaClinica"
            rules={[
              { required: false },
            ]}
            hidden={true}
          >
            <Input />
          </Form.Item>

          {/* Nombres */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"Nombres"}
              labelCol={{ span: 24 }}
              name="nombres"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input placeholder="Nombre categoria" />
            </Form.Item>
          </Col>

          {/* Edad */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"Edad"}
              labelCol={{ span: 24 }}
              name="edad"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input placeholder="Nombre categoria" />
            </Form.Item>
          </Col>

          {/* tipoParto */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="tipoParto"
              label="Tipo de parto"
              labelCol={{ span: 24 }}
              hasFeedback
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Select>
                {
                  window.tipoParto.map((op) => {
                    return (
                      <Option key={op} value={op}>
                        {op}
                      </Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>

          {/* episiotomia */}
          <Col xs={12} sm={3} md={4} xl={4}>
            <Form.Item
              label={"Episiotomía"}
              labelCol={{ span: 24 }}
              name="episiotomia"
              rules={[
                { required: false, message: "¡Valor obligatorio!" },
              ]}
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={episiotomia}
                onChange={()=>setEpisiotomia(!episiotomia)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default HijosAdd;
