import React, { useState, useEffect } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { Tooltip, Button, Tag } from 'antd'
import { PlusCircleOutlined, ReloadOutlined, LockOutlined } from "@ant-design/icons"
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography,  makeStyles } from '@material-ui/core'
import { sendServer } from '../../utils/utils'
import UsuarioAdd from './UsuarioAdd'
import UsuarioUpd from './UsuarioUpd'
import CambioClave from './CambioClave'
import Niveles from './Niveles'

const useStyles = makeStyles(theme => ({
    table: {
        '& tr:nth-child(even)': {
            background: '#dedede',
        },
    },
}));

const Usuarios = () => {

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [state, setState] = useState(true);
    const [flagMdAdd, setFlagMdAdd] = useState(false);
    const [flagMdUpd, setFlagMdUpd] = useState(false);
    const [dataUpd, setDataUpd] = useState({});
    const [dataUpdPass, setDataUpdPass] = useState({});
    const [flagCambiaClave, setflagCambiaClave] = useState(false);
    const [flagNiveles, setflagNiveles] = useState(false);
    const [dataUpdNiveles, setDataUpdNiveles] = useState({});

    const loadData = async () => {
        try {
            setState(true);
            const { data } = await sendServer(null, 'GET', 'user/listar');
            setData(data);
            setState(false);
        } catch (error) {
            setState(false);
        }
    }

    useEffect(() => {
        loadData();
        return (() => { });
    }, [])

    const upd = (data) => {
        setDataUpd(data);
        setFlagMdUpd(true);
    }

    const handleUpdatePass = (data) => {
        setDataUpdPass(data);
        setflagCambiaClave(true);
    }

    const handleUpdateNiveles = (data) => {
        setDataUpdNiveles(data);
        setflagNiveles(true);
    }

    const columns = [

        {
            title: 'Nombres',
            field: 'nombres',
        },
        {
            title: 'Rol',
            field: 'rol',
        },
        {
            title: 'Nombre usuario',
            field: 'userName',
        },
        {
            title: 'Estado',
            field: 'estado',
            render: fila => {
                if (fila.estado) {
                    return <Tag color="green">ACTIVO</Tag>
                } else {
                    return <Tag color="red">ANULADO</Tag>
                }
            }
        },
        {
            title: 'Cambio clave',
            field: 'cambioClave',
            render: fila => {
                if (fila.cambioClave) {
                    return <Tag color="green">SI</Tag>
                } else {
                    return <Tag color="red">NO</Tag>
                }
            }
        },
    ]

    return (
        <div style={{ marginTop: 20 }} className={classes.table}>

            <MaterialTable
                title={
                    <Typography
                        align="center"
                        variant="h5"
                        color="primary"
                        style={{ fontWeight: 'bold' }}
                    >Lista de usuarios</Typography>
                }
                columns={columns}
                data={data}
                key='id'
                isLoading={state}
                localization={{
                    header: {
                        actions: 'Acción',
                    },
                }}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: '#01579b',
                        color: '#FFF',
                    },
                }}
                actions={[
                    {
                        icon: 'edit',
                        iconProps: { style: { color: "#40a9ff" } },
                        tooltip: 'Editar',
                        onClick: (event, rowData) => upd(rowData)
                    },
                    {
                        icon: () => <LockOutlined />,
                        tooltip: 'Cambiar clave',
                        onClick: (event, rowData) => { handleUpdatePass(rowData) }
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faHeartbeat} style={{color: 'red'}} />,
                        tooltip: 'Niveles',
                        onClick: (event, rowData) => { handleUpdateNiveles(rowData) }
                    }
                ]}
                components={{
                    Toolbar: props => (
                        <div className="myToolbar">
                            <MTableToolbar {...props} />
                            <div style={{ padding: '0px 10px' }}>
                                <Tooltip placement="top" title="Agregar" >
                                    <Button size="middle" onClick={() => setFlagMdAdd(true)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <PlusCircleOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Sincronizar">
                                    <Button size="middle" onClick={() => loadData()}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <ReloadOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                }}
            />
            <UsuarioAdd
                visible={flagMdAdd}
                setVisible={setFlagMdAdd}
                loadData={loadData}
            />

            <UsuarioUpd
                visible={flagMdUpd}
                setVisible={setFlagMdUpd}
                loadData={loadData}
                dataUpd={dataUpd}
            />

            <CambioClave
                visible={flagCambiaClave}
                setVisible={setflagCambiaClave}
                dataUpd={dataUpdPass}
            />

            <Niveles
                visible={flagNiveles}
                setVisible={setflagNiveles}
                dataUpd={dataUpdNiveles}
            />
        </div>
    )
}

export default Usuarios;
