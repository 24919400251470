import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Row, Col, Select, Button, Table } from "antd"
import { sendServer, toast, confirm } from '../../utils/utils'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
    makeStyles,
    AppBar,
    Tabs,
    Typography,
    Box,
    Tab
} from '@material-ui/core'

const { Option } = Select

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 30,
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const Niveles = ({ visible, setVisible, dataUpd }) => {

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [form] = Form.useForm();
    const [niveles, setNiveles] = useState([]);
    const [nivelesXUsuario, setNivelesXUsuario] = useState([]);

    useEffect(() => {
        if (visible) {
            loadData();
            form.setFieldsValue({ idUsuario: dataUpd.id });
        }
        return () => { form.resetFields() }
    }, [visible])

    const loadData = async () => {
        try {
            const { data: niveles } = await sendServer(null, 'GET', 'master/nivel/listarAdmin');
            const { data: nivelesXUsuario } = await sendServer(null, 'GET', `master/nivel/listarNivelesXusuario/${dataUpd.id}`);
            setNiveles(niveles);
            setNivelesXUsuario(nivelesXUsuario);
        } catch (error) {
            console.log(error)
        }
    }

    const agregarNivel = async (item) => {

        try {
            console.log(item);
            const { data } = await sendServer(item, 'POST', 'master/nivel/agregarNivelXUsuario');
            toast('success', 'fitconluapp', 'Guardado correctamente');
            loadData();
        } catch (e) {
            toast('info', 'fitconluapp', e.message);
        }

    };

    const eliminarNivelXUsuario = async (item) => {

        try {
            confirm('fitconluapp', 'Desea eliminar ? Esta operación no se puede revertir', async () => {
                const { data } = await sendServer(null, 'DELETE', `master/nivel/eliminarNivelXUsuario/${item.id}`);
                toast('success', 'fitconluapp', 'Eliminado correctamente');
                loadData();
            });
        } catch (e) {
            const { status, msg } = e;
            toast('error', 'fitconluapp', 'No se puede eliminar');
            console.log(e);
        }

    };

    const closeModal = () => {
        form.resetFields();
        setVisible(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const columnsNiveles = [
        {
            title: 'Nombre',
            dataIndex: 'nombre'
        },
        {
            title: 'Fecha inicio',
            dataIndex: 'fechaInicio',
            render: (_, fila) => <>{moment(fila.fechaInicio).format('YYYY-MM-DD')}</>
        },
        {
            title: 'Fecha fín',
            dataIndex: 'fechaFin',
            render: (_, fila) => <>{moment(fila.fechaFin).format('YYYY-MM-DD')}</>
        },
    ]

    return (
        <Modal
            width={1000}
            title={dataUpd.nombres}
            visible={visible}
            onOk={form.submit}
            onCancel={() => { closeModal() }}
            footer={null}
            className="modalNiveles"
        >
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Relacionar nivel" {...a11yProps(0)} />
                        <Tab label="Niveles" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>

                {/* RELACIONAR NIVEL A UN USUARIO */}
                <TabPanel value={value} index={0}>
                    <Form
                        autocomplete="off"
                        onFinish={agregarNivel}
                        form={form}
                        style={{ marginTop: 15, padding: 5 }}
                    >
                        <Row gutter="24">

                            <Form.Item
                                name="idUsuario"
                                hidden={true}
                                rules={[
                                    { required: true },
                                ]}
                            >
                                <Input type="text" />
                            </Form.Item>

                            {/* idNivel */}
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                    name="idNivel"
                                    label="Nivel"
                                    labelCol={{ span: 24 }}
                                    hasFeedback
                                    rules={[
                                        { required: true, message: "¡Valor obligatorio!" },
                                    ]}
                                >
                                    <Select>
                                        {
                                            niveles.map((op) => {
                                                return (
                                                    <Option key={op.idNivel} value={op.idNivel}>
                                                        {op.nombre}
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/* fechaInicio */}
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    label={"Fecha inicio"}
                                    labelCol={{ span: 24 }}
                                    name="fechaInicio"
                                    rules={[
                                        { required: true, message: "¡Valor obligatorio!" },
                                    ]}
                                >
                                    <Input type="date" style={{ width: '100%', height: '30px' }} />
                                </Form.Item>
                            </Col>


                            {/* fechaFin */}
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    label={"Fecha fín"}
                                    labelCol={{ span: 24 }}
                                    name="fechaFin"
                                    rules={[
                                        { required: true, message: "¡Valor obligatorio!" },
                                    ]}
                                >
                                    <Input type="date" style={{ width: '100%', height: '30px' }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} style={{ padding: 15 }}></Col>
                            <Button type="primary" style={{ margin: 5 }}  onClick={form.submit} >
                                Guardar
                            </Button>
                        </Row>
                    </Form>
                </TabPanel>

                {/* LISTA DE NIVELES POR USUARIO */}
                <TabPanel value={value} index={1}>
                    <Table
                        rowKey="id"
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: (event) => {eliminarNivelXUsuario(record)}
                            };
                        }}
                        style={{marginTop: 15, padding: 5}}
                        dataSource={nivelesXUsuario} 
                        columns={columnsNiveles} 
                    />
                </TabPanel>
            </div>
        </Modal>
    );
};

export default Niveles;
