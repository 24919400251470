import React, { useEffect } from "react"
import { Modal, Form, Input, Row, Col } from "antd"
import { sendServer, toast } from '../../utils/utils'

const CambioClave = ({ visible, setVisible, dataUpd, sendFrmLogin }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    console.log('CAMBIO CLAVE');
    form.setFieldsValue({
      ...dataUpd
    })
  }, [visible])

  const handleSubmit = async (item) => {

    try {
      console.log(item)
      const { data } = await sendServer(item, 'POST', 'updPwdYcambioClave');
      sendFrmLogin({
        userName: dataUpd.userName,
        clave: item.clave
      });
    } catch (e) {
      toast('info', 'fitconluapp', e);
    }

  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  }

  const hadleEnter = ({ keyCode }) => {
    if(keyCode === 13) {
      form.submit();
    }  
  }

  return (
    <Modal
      title="¡Cambia tu contraseña!"
      visible={visible}
      onOk={form.submit}
      onCancel={() => { closeModal() }}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        name="cambia_clave"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">
          {/* cambioClave */}
          <Form.Item
            name="cambioClave"
            rules={[
              { required: true},
            ]}
            hidden={true}
          >
            <Input />
          </Form.Item>

          {/* userName */}
          <Form.Item
            name="userName"
            rules={[
              { required: true},
            ]}
            hidden={true}
          >
            <Input />
          </Form.Item>

          {/* nueva contraseña */}
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={"Nueva contraseña"}
              labelCol={{ span: 24 }}
              name="clave"
              rules={[
                { required: true, message: "Por favor ingresa la contraseña!" },
              ]}
            >
              <Input type="password" size="large" onKeyUp={hadleEnter} placeholder="Contraseña" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CambioClave;
