
import React, { useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Template from '../views/template/Template'
import useToken from '../components/hooks/useToken'



const PrivateRoute = ({component: Component, ...rest}) => {

    const { token: user, setToken } = useToken();
    const [modulosUser, setmodulosUser] = useState([
        'home',
        'clases',
        'historia-clinica'
    ])
    if(!user) {
        return(
            <Route {...rest} render={props => (
                <Redirect to="/" />
            )} />    
        )
    }
    if(user.rol === 'admin') {
        return(
            <Route {...rest} render={props => (
                <Template component={Component} {...rest}/>
            )} />    
        )
    } else {
        for (let i = 0; i < modulosUser.length; i++) {
            const modulo = modulosUser[i];
            const { path } = rest;
            const [,pathFinal] = path.split('/');
            if(pathFinal === modulo) {
                return(
                    <Route {...rest} render={props => (
                        <Template component={Component} {...rest}/>
                    )} />    
                )
            }
        }
        return(
            <Route {...rest} render={props => (
                <Redirect to="/home" />
            )} />    
        )
    }
};

export default PrivateRoute;