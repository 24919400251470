import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import { Container, Grid, Typography, Divider } from "@material-ui/core"
import { Pagination } from '@material-ui/lab'
import { makeStyles } from "@material-ui/core/styles"
import { Button } from 'antd'
import { sendServer, backHistory, getKeySessionStorage, getSesion } from '../../utils/utils'
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const useStyles = makeStyles((theme) => ({
    playerWrapper: {
        width: "100%",
        position: "relative",
    },

    controlsWrapper: {
        visibility: "hidden",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.4)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    topControls: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(2),
    },
    middleControls: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomWrapper: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
    },

    bottomControls: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    button: {
        margin: theme.spacing(1),
    },
    controlIcons: {
        color: "#777",

        fontSize: 50,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#fff",
            transform: "scale(1)",
        },
    },

    bottomIcons: {
        color: "#999",
        "&:hover": {
            color: "#fff",
        },
    },

    volumeSlider: {
        width: 100,
    },

    pagination: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 5
    }
}));


const format = (seconds) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
};

let count = 0;

const Clases = ({ props, setotroPatch, setotroPatchTitle }) => {

    const classes = useStyles();
    const history = useHistory();

    const [clases, setclases] = useState([]);
    const [currentVideo, setcurrentVideo] = useState({url:''});
    const [currentNivel, setcurrentNivel] = useState({});

    const handlePagination = (item, page) => {
        selectVideoDefault(clases, page);
    }

    const loadData = async () => {
        try {
            const { computedMatch: { params: { idNivel } } } = props;
            const { data } = await sendServer(null, 'GET', `master/clases/getClasesXNivel/${1}/${idNivel}`);
            setclases(data);
            selectVideoDefault(data, 1);
        } catch (e) {
            console.log(e)
        }
    }

    const selectVideoDefault = (data, ordenVideo) => {
        const { token } = getSesion();
        if(data && ordenVideo) {
            for (let i = 0; i < data.length; i++) {
                const clase = data[i];
                if(clase.orden === ordenVideo) {
                    const url = `${window.window.baseUrlVideos}/${clase?.recurso}/${token}`;
                    setcurrentVideo({...clase, url});
                    return;
                }
            }
        }
    }

    const othersLoad = () => {
        const { computedMatch: { params: { programa, idNivel } } } = props;
        setotroPatch(true);
        setotroPatchTitle(programa);
        //saco el nivel
        const nivelesRemote = getKeySessionStorage('remoteNiveles');
        nivelesRemote.map(nivel => {
            if(nivel.idNivel == idNivel) {
                setcurrentNivel(nivel);
            }
        })
    }

    useEffect(() => {
        loadData();
        othersLoad();
        /*const interval = setInterval(() => {
            const sesion = getSesion();
            const { niveles: nivelesLocal } = getSesion();
            const nivelesRemote = getKeySessionStorage('remoteNiveles');
            const countLocal = parseInt(nivelesLocal.length);
            const countRemote = parseInt(nivelesRemote.length);
            if (countLocal !== countRemote) {
                sesion.niveles = nivelesRemote;
                sessionStorage.setItem('sesion', JSON.stringify(sesion));
                console.log(`cambiaron los niveles en clases: ${JSON.stringify(nivelesRemote)}`);
                history.push('/home');
            }
        }, 60000);*/
        return () => {
            //clearInterval(interval);
            setotroPatch(true);
            setotroPatchTitle('');
            console.log('se limpia interval de clases')
        }
    }, [])

    return (
        <>
            <Button type="default" 
                onClick={backHistory} 
                icon={<FontAwesomeIcon icon={faHandPointLeft} />} 
                style={{ marginTop: 30,  width: 100, display: 'block' }}>
            </Button>
            <Typography variant="h4" align="center" color="primary">{currentVideo.nombre}</Typography>
            <Typography variant="body1" align="center" gutterBottom>{currentVideo.descripcion}</Typography>
            <Container maxWidth="lg" style={{ marginTop: 30 }}>
                <div className={classes.playerWrapper}>
                    {/* {currentVideo.url} */}
                    <video 
                        style={{height: '100%', width: '100%'}} 
                        src={currentVideo.url}
                        autoPlay
                        controlsList="nodownload"
                        controls    
                        loop
                        playsInline
                        onContextMenu={(e) => e.preventDefault()}
                    />
                    
                </div>

                <Grid container justify="center" style={{marginTop: 10}}>
                    <Pagination count={clases.length} color="primary" onChange={handlePagination} />
                </Grid>

                <Divider variant="middle" style={{marginTop:30}} />
                <Typography style={{margin: '20px'}} variant="h6" align="center" gutterBottom>{currentNivel.nombre}</Typography>
                <Typography style={{margin: '20px'}} variant="body1" gutterBottom>{currentNivel.descripcion}</Typography>
                <Divider variant="middle" />
            </Container>

        </>
    );
}

export default Clases;