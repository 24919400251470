
import React, { useState, useEffect } from 'react'
import { Table, Button, Space, Card, Input, Tag } from 'antd'
import { EditOutlined, DeleteOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons"
import { sendServer, toast, confirm } from '../../utils/utils'
import ProgramaAdmAdd from './ProgramaAdmAdd'
import ProgramaAdmUpd from './ProgramaAdmUpd'

const ProgramasAdm = () => {

    const [data, setData] = useState([]);
    const [state, setState] = useState(true);
    const [flagMdAdd, setFlagMdAdd] = useState(false);
    const [flagMdUpd, setFlagMdUpd] = useState(false);
    const [dataUpd, setDataUpd] = useState({});

    const loadData = async () => {
        try {
            setState(true);
            const { data } = await sendServer(null, 'GET', 'programa/listarAdmin');
            setData(data);
            setState(false);
        } catch (error) {
            setState(false);
        }
    }

    useEffect(() => {
		Promise.all([
			loadData(),
            console.log('PADRE')
		]).then(() => {
			//setIsLoading(false)
		})
		return (() => {
			//setIsLoading(true)
		})
	}, [])

    const upd = (data) => {
        setDataUpd(data);
        setFlagMdUpd(true);
    }
    
    const del = async (id) => {
        try {
            confirm('fitconluapp', 'Desea eliminar ? Esta operación no se puede revertir', async () => {
                const { msg } = await sendServer(null, 'DELETE', `programa/eliminar/${id}`);
                toast('success', 'fitconluapp', 'Eliminado correctamente');
                loadData();
            });
        } catch (e) {
            const { status, msg } = e;
            toast('error', 'fitconluapp', 'No se puede eliminar');
            console.log(e);
        }
    }

    const inputFilter = async (val) => {
        try {
            if(val) {
                setState(true);
                const { data } = await sendServer(null, 'GET', `programa/listarFilter/${val}`);
                setData(data);
                setState(false);
            } else {
                loadData();
            }
        } catch (error) {
        }
    }

    const columns = [
        {
            title: 'acción',
            dataIndex: 'cmd',
            width: 150,
            render: (_, fila) => <> 
                <Button type="primary" onClick={() => upd(fila)}><EditOutlined /></Button> {" "} 
                <Button type="primary" danger onClick={() => del(fila.id)}><DeleteOutlined /></Button> </>,
        },
        {
            title: 'categoria',
            dataIndex: 'nombre_categoria',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.nombre_categoria?.localeCompare(b.nombre_categoria)
        },
        {
            title: 'nombre',
            dataIndex: 'nombre',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.nombre?.localeCompare(b.nombre)
        },
        {
            title: 'sku',
            dataIndex: 'sku',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.sku?.localeCompare(b.sku)
        },
        {
            title: 'descripción',
            dataIndex: 'descripcion',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.descripcion?.localeCompare(b.descripcion)
        },
        {
            title: 'vigencia',
            dataIndex: 'vigencia',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                if(a && b) {
                    return  a.id - b.id;
                }
            },
        },
        {
            title: 'estado',
            dataIndex: 'estado',
            sorter: false,
            width: 15,
            render: (_, fila) => 
            <> 
                {fila.estado
                    ? <Tag color="success">activo</Tag>
                    : <Tag color="error">anulado</Tag>
                }
            </>,
            filters: [
                {
                  text: 'activo',
                  value: 1,
                },
                {
                  text: 'anulado',
                  value: 0,
                },
            ],
            onFilter: (value, record) => record.estado === value,

        },
    ]

    return (
        
        <Card 
            title="Lista de programas" 
            bordered  
            style={{ boxShadow: "0px 8px 20px #00000033" }}
            className="misCards"
        >
            <Space style={{ marginBottom: 16 }}>
                <Button onClick={() => { setFlagMdAdd(true) }}><PlusCircleOutlined /></Button>
                <Button onClick={() => { loadData() }}><ReloadOutlined /></Button>
            </Space>
            <Input.Search allowClear style={{ width: '100%', marginBottom: 5 }} onSearch={(e)=>inputFilter(e)} placeholder="Buscar..."/>
            <Table 
                rowKey="id"
                className="tablas"
                columns={columns} 
                dataSource={data} 
                loading={state}
                scroll={{
                    x: '100%',
                }}
            />
            <ProgramaAdmAdd 
                visible={flagMdAdd} 
                setVisible={setFlagMdAdd} 
                loadData={loadData} 
            />

            <ProgramaAdmUpd 
                visible={flagMdUpd} 
                setVisible={setFlagMdUpd} 
                loadData={loadData} 
                dataUpd={dataUpd}
            />
        </Card>
    )
}

export default ProgramasAdm
