import { Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import request from 'axios';

const baseUrl = process.env.REACT_APP_BASE;

const logout = async () => {
  sessionStorage.clear();
  window.location="/";
}

/**
 * Recupera la sesion
 * @returns 
 */
const getSesion = () => {
  const sesionString = sessionStorage.getItem('sesion');
  const userToken = JSON.parse(sesionString);
  return userToken;
}

/**
 * 
 * @param {*} key 
 * @returns 
 */
const getKeySessionStorage = (key) => {
  const sesionString = sessionStorage.getItem(key);
  const userToken = JSON.parse(sesionString);
  return userToken;
}

/**
 * Cambia color
 * @param {*} id 
 * @param {*} color 
 */
const changeColor = (id, color) => {
  const element = document.querySelector(id);
  element.style.backgroundColor = color;
}

/**
 * mensaje tipo toast
 * @param {*} type 
 * @param {*} message 
 * @param {*} description 
 */
const toast = (type, message, description) => {
  notification[type]({message,description});
};

/**
 * Mensaje tipo confirm
 * @param {*} title 
 * @param {*} content 
 * @param {*} callcack 
 */
const confirm = (title, content, callcack) => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText: 'Si',
    cancelText: 'No',
    onOk() {
      callcack();
    },
  });
}

/**
 * Solo para hacer login
 * @param {*} data 
 * @returns 
 */
const sendServerLogin = async (data) => {

  try {
    const result = await request({
      method: "POST",
      url: `${baseUrl}/api/login`,
      data,
      headers: {
        "content-type": "application/json",
      },
    })
    return result?.data;
  } catch (e) {
    throw (e.response.data);
  }
};

/**
 * Envia al server cualquier cosa con sesion
 * @param {*} data 
 * @param {*} method 
 * @param {*} action 
 * @returns 
 */
const sendServer = async (data, method, action) => {

  const { id, token } = getSesion() || '';
  try {
    const result = await request({
      method,
      url: `${baseUrl}/api/${action}`,
      data,
      headers: {
        'access-token': token,
        "Content-Type": "application/json",
      },
    })
    return result?.data;
  } catch (e) {
    const msg = e.message;
    if(msg.includes('401')){
      toast('error', 'fitconluapp', 'sesión invalida');
      logout();
      console.log(msg)
    } else {
      throw (e.response.data);
    }
  }
};

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const cleanAcentos = (text) => { 
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "_");
}

const backHistory = () => {
  window.history.back();
}

export {
  sendServerLogin,
  changeColor,
  sendServer,
  confirm,
  toast,
  getSesion,
  logout,
  uuidv4,
  cleanAcentos,
  backHistory,
  getKeySessionStorage
};
