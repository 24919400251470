import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Row, Col, Select, Switch } from "antd"
import { sendServer, toast } from '../../utils/utils'
import {
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons"
const { Option } = Select

const UsuarioAdd = ({ visible, setVisible, loadData }) => {
  
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      estado: true,
      cambioClave: false
    })
    console.log('HIJO ADD');
  }, [visible])
  
  const handleSubmit = async (item) => {

    try {
      item.estado = item.estado ? 1 : 0;
      item.cambioClave = item.cambioClave ? 1 : 0;
      console.log(item);
      const { data } = await sendServer(item, 'POST', 'user/agregarUsuario');
      loadData();
      toast('success', 'fitconluapp', 'Agregado correctamente');
      closeModal();
    } catch (e) {
      const { status, data } = e;
      if(data === 'algunCampoDuplicado') {
        toast('warning', 'fitconluapp', 'No se puede agregar, usuario duplicado');
      } else if(data === 'The given data was invalid.'){
        toast('warning', 'fitconluapp', 'Faltan campos obligatorios');
      } else {
        toast('warning', 'fitconluapp', `No se puede agregar - ${data}`);
      }
    }
  
  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  }

  return (
    <Modal
      title="Agregar usuario"
      visible={visible}
      width={1000}
      onOk={form.submit}
      onCancel={()=>{closeModal()}}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        autocomplete="off"
        name="usuario_add"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Nombres"}
              labelCol={{span:24}}
              name="nombres"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input placeholder="Nombres del usuario" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              name="rol"
              label="Rol"
              labelCol={{span:24}}
              hasFeedback
              rules={[{ required: true, message: '¡Valor obligatorio!' }]}
            >
              <Select showSearch placeholder="Seleccione un rol">
                <Option value="user">user</Option>
                <Option value="admin">admin</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Nombre de usuario"}
              labelCol={{span:24}}
              name="userName"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input type="text" placeholder="Nombre de usuario" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Clave"}
              labelCol={{span:24}}
              name="clave"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input type="password" placeholder="Clave" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={3}>
            <Form.Item
              label={"Estado"}
              labelCol={{ span: 24 }}
              name="estado"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                onChange={(val) => form.setFieldsValue({estado:val})}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={3}>
            <Form.Item
              label={"Cambio clave"}
              labelCol={{ span: 24 }}
              name="cambioClave"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(val) => form.setFieldsValue({cambioClave:val})}
              />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

export default UsuarioAdd;
