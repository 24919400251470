import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Row, Col, Switch, Select } from "antd"
import { sendServer, toast } from '../../utils/utils'
import {
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons";
const { Option } = Select

const ProgramaAdmAdd = ({ visible, setVisible, loadData }) => {

  const [form] = Form.useForm();
  const [estado, setEstado] = useState(true);
  const [categorias, setCategorias] = useState();

  useEffect(() => {
    Promise.all([
      loadDataLocal(),
      console.log('HIJO ADD')
    ]).then(() => {
      //setIsLoading(false)
    })
    return (() => {
      //setIsLoading(true)
    })
  }, [visible])

  const loadDataLocal = async () => {
    const { data } = await sendServer(null, 'GET', 'categoria/listar');
    const options = data.map(d => <Option key={d.key}>{d.nombre}</Option>);
    setCategorias(options);
  }

  const handleSubmit = async (item) => {

    try {
      item.estado = estado ? 1 : 0;
      console.log(item);
      const { msg } = await sendServer(item, 'POST', 'programa/agregar');
      loadData();
      toast('success', 'fitconluapp', 'Agregado correctamente');
      closeModal();
    } catch (e) {
      const { status, msg } = e;
      if (msg === 'algunCampoDuplicado') {
        toast('warning', 'fitconluapp', 'No se puede agregar, programa duplicado');
      } else {
        toast('warning', 'fitconluapp', `No se puede agregar - ${msg}`);
      }
    }

  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
    setEstado(true);
  }

  return (
    <Modal
      title="Agregar programa"
      visible={visible}
      width={1000}
      onOk={form.submit}
      onCancel={() => { closeModal() }}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        name="programa_add"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">

          {/* CATEGORIAS */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="id_categoria"
              label="Categoria"
              labelCol={{ span: 24 }}
              hasFeedback
              rules={[{ required: true, message: 'Por favor selecione la categoria!' }]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Seleccione una categoria"
                filterOption={(inputValue, option) => (
                  option.children.toLowerCase().includes(inputValue.toLowerCase())
                )}
              >
                {categorias}
              </Select>
            </Form.Item>
          </Col>

          {/* NOMBRE */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"Nombre"}
              labelCol={{ span: 24 }}
              name="nombre"
              rules={[
                { required: true, message: "Por favor ingresa el nombre!" },
              ]}
            >
              <Input size="large" placeholder="Nombre programa" />
            </Form.Item>
          </Col>

          {/* SKU */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"SKU"}
              labelCol={{ span: 24 }}
              name="sku"
              rules={[
                { required: true, message: "Por favor ingresa el sku!" },
              ]}
            >
              <Input size="large" placeholder="sku programa" />
            </Form.Item>
          </Col>

          {/* DESCRIPCION */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"Descripción"}
              labelCol={{ span: 24 }}
              name="descripcion"
              rules={[
                { required: false, message: "Por favor ingresa el descripción!" },
              ]}
            >
              <Input size="large" placeholder="Descripción programa" />
            </Form.Item>
          </Col>

          {/* VIGENCIA */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={"Vigencia"}
              labelCol={{ span: 24 }}
              name="vigencia"
              rules={[
                { required: true, message: "Por favor ingresa el Vigencia!" },
              ]}
            >
              <Input type="number" size="large" placeholder="Vigencia del programa" />
            </Form.Item>
          </Col>

          {/* ESTADO */}
          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Estado"}
              labelCol={{ span: 24 }}
              name="estado"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                onChange={() => setEstado(!estado)}
              />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

export default ProgramaAdmAdd;
