import Icono from "../../img/icon_fit.png";

import { Card, Button, Row, Col, Avatar } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getSesion, uuidv4, cleanAcentos } from "../../utils/utils";

const { Meta } = Card;

const Home = () => {
  const [niveles, setniveles] = useState([]);
  const [loadCard, setloadCard] = useState(true);

  useEffect(() => {
    const { niveles } = getSesion();
    setniveles(niveles);
    setloadCard(false);
  }, []);

  const listItems = niveles.map((nivel) => {
    const template = (
      <>
        <Col
          sm={24}
          md={11}
          lg={8}
          xl={7}
          xxl={5}
          className={"colNivel"}
          key={uuidv4}
        >
          <Card
            className={"cardNivel"}
            hoverable
            bordered
            loading={loadCard}
            cover={
              <img
                className={"imgCard"}
                id={nivel.idNivel}
                alt="example"
                src={`${window.baseUrlFiles}/${nivel.recurso}`}
              />
            }
            actions={[
              <Button type="primary" ghost style={{ width: "90%" }}>
                <Link
                  to={`/clases/${cleanAcentos(nivel.nombrePrograma)}/${
                    nivel.idNivel
                  }`}
                >
                  ¡Empecemos!
                </Link>
              </Button>,
            ]}
          >
            <Meta
              id={nivel.idNivel}
              avatar={<Avatar src={Icono} style={{ width: 25, height: 25 }} />}
              title={`${nivel.nombrePrograma}`}
              description={nivel.nombre}
            />
          </Card>
        </Col>
      </>
    );
    return template;
  });

  return (
    <Row style={{ alignItems: "center", justifyContent: "center" }}>
      {listItems}
    </Row>
  );
};

export default Home;
