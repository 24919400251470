import React, { useState, useEffect } from 'react'
import { Button, Tooltip } from 'antd'
import MaterialTable, { MTableToolbar } from 'material-table'
import { Typography, Paper, makeStyles } from '@material-ui/core'
import { ReloadOutlined } from "@ant-design/icons"
import VisibilityIcon from '@material-ui/icons/Visibility'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import moment from 'moment'
import { sendServer } from '../../utils/utils'

const useStyles = makeStyles(theme => ({
    table: {
     /*  '& tbody>.MuiTableRow-root:hover': {
        background: '#dedede',
      }, */
      '& tr:nth-child(even)': {
        background: '#dedede',
      },
    },
}));

const Historias = ({ setPaciente, setFlagComponente }) => {

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [state, setState] = useState(true);

    useEffect(() => {
        //sessionStorage.removeItem('filtroHijos');
        loadData();
        return () => { }
    }, [])

    const loadData = async () => {
        try {
            setState(true);
            const { data } = await sendServer(null, 'GET', `master/historia/listar`);
            setData(data);
            setState(false);
        } catch (error) {
            console.log(error)
        }
    }

    const handleReload = () => {
        loadData();
        sessionStorage.removeItem('filtroHijos');
    }

    const columns = [
        {
            title: 'Usuario',
            field: 'nombresUsuario'
        },
        {
            title: 'Nombres',
            field: 'nombreCompleto'
        },
        {
            title: 'Edad',
            field: 'edad',
        },
        {
            title: 'Residencia',
            field: 'lugarResidencia',
        },
        {
            title: 'Profesión',
            field: 'profesion',
        },
        {
            title: 'Editado',
            field: 'updatedAt',
            render: fila => {
                const fecha = moment(fila.updatedAt).format('YYYY-MM-DD h:mm a');
                if(fecha !== 'Invalid date') {
                   return fecha;
                }
            }
        },
    ]

    return (
        <div style={{marginTop: 20}} className={classes.table}>
            <Typography
                align="center"
                variant="h5"
                color="primary"
                style={{ fontWeight: 'bold' }}
            >Historias clínica</Typography>
            <MaterialTable
                title={''}
                style={{}}
                columns={columns}
                data={data}
                key='id'
                isLoading={state}
                localization={{
                    header: {
                        actions: 'Acción',
                    },
                }}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: '#01579b',
                        color: '#FFF',
                    },
                }}
                actions={[
                    {
                        icon: () =>  <AttachFileIcon style={{color: "#87d068"}} />,
                        tooltip: 'Seleccionar hisotia',
                        onClick: (event, rowData) => {
                           if(rowData.id) {
                            sessionStorage.setItem('filtroHijos', JSON.stringify({
                                idHistoria: rowData.id,
                                nombrePaciente: rowData.nombreCompleto
                            }));
                           } else {
                            sessionStorage.removeItem('filtroHijos');
                           }
                           
                        }
                    },
                    {
                        icon: () =>  <VisibilityIcon style={{color: "#40a9ff"}} />,
                        tooltip: 'Ver historias clínica',
                        onClick: (event, rowData) => {
                            const { idUsuario, nombresUsuario } = rowData;
                            console.log('paciente: ',rowData);
                            setPaciente({
                                id:idUsuario,
                                nombreCompleto: nombresUsuario
                            });
                            setFlagComponente('MisDatos');
                        }
                    },
                ]}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>,
                    Toolbar: props => (
                        <div className="myToolbar">
                            <MTableToolbar {...props} />
                            <div style={{ padding: '0px 10px' }}>
                                <Tooltip placement="top" title="Sincronizar">
                                    <Button size="middle" onClick={() => handleReload()}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <ReloadOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                }}
            />
        </div>
    )
}

export default Historias
