import "./style.css"
import logo from "../../img/logo.svg"
import React, { useState } from "react"
import { Card, Col, Row, Form, Input, Button, Checkbox } from "antd"
import { UserOutlined, LockOutlined, SendOutlined } from "@ant-design/icons"
import useToken from '../../components/hooks/useToken'
import { sendServerLogin, toast } from '../../utils/utils'
import CambioClave from './CambioClave'

const Login = () => {

  const { token, setToken } = useToken();
  const [dataUpd, setdataUpd] = useState({
    userName: null,
    cambioClave: null
  })
  const [flagCambiaClave, setflagCambiaClave] = useState(false);

  if (token) {
    window.location = "/home";
  }

  const sendFrmLogin = async (user) => {
    try {
      const { status, data } = await sendServerLogin(user);
      setToken(data);
      window.location = "/home";
    } catch (error) {
      const { data } = error;
      if (data === 'loginFail') {
        toast('info', 'fitconluapp', 'no se puede iniciar sesión, valida las credenciales e intenta de nuevo');

      } else if (data === 'cambieDeClave') {
        dataUpd.userName = user.userName;
        dataUpd.cambioClave = 1;
        setflagCambiaClave(true);

      } else {
        toast('info', 'fitconluapp', data);
      }
    }
  };

  return (
    <>
      <Row justify="center">
        <Col>
          <Card
            cover={
              <img
                className="loginImg"
                alt="fitconlu.com"
                src={logo}
              />
            }
            title="fitconluapp"
            extra={<a href="https://fitconlu.com/" target="_blank">fitconlu.com</a>}
            className="cardLogin"
          >
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={sendFrmLogin}
            >
              <Form.Item
                name="userName"
                rules={[
                  { required: true, message: "Por favor ingresa el Usuario!" },
                ]}
              >
                <Input
                  style={{ borderRadius: 10 }}
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Usuario"
                />
              </Form.Item>
              <Form.Item
                name="clave"
                rules={[
                  { required: true, message: "Por favor ingresa Contraseña!" },
                ]}
              >
                <Input
                  style={{ borderRadius: 10 }}
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Contraseña"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: '100%' }}
                >
                  INICIAR
                  <SendOutlined />
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>

      <CambioClave 
        visible={flagCambiaClave} 
        setVisible={setflagCambiaClave} 
        dataUpd={dataUpd}
        sendFrmLogin={sendFrmLogin}
      />
    </>
  );
};

export default Login;
